import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { debounce } from 'lodash';
import 'url-search-params-polyfill';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { overrideControl, overrideThemes, overrideComponents } from 'ui/utils/reactSelect';
import { SearchUser } from 'ui/types';

import RecipientOption from './RecipientOption';

const NoOptionsMessage = props => <components.NoOptionsMessage {...props}>No users found</components.NoOptionsMessage>;

interface Props {
  url: string,
  additionalParams: Record<string, unknown>,
  name: string,
  onChange: Function,
  users: SearchUser[],
  displaySelectedValues: boolean,
  resetSearch: boolean,
  setResetSearch: null | React.Dispatch<React.SetStateAction<boolean>>,
  placeholder: string,
  isClearable: boolean,
}

const defaultAdditionalParams = {};

export const UserSearch: React.FC<Props> = ({
  name, onChange, users, displaySelectedValues,
  additionalParams = defaultAdditionalParams, resetSearch = false, setResetSearch = null, isClearable = false,
  url = '/api/v2/users/search', placeholder = 'Begin typing to find a user...',
}) => {
  const [value, setValue] = useState<SearchUser | SearchUser[] | null>(users);
  const formatLabel = (option: SearchUser) => <RecipientOption {...option} />;

  const debouncedSearch = useMemo(() => debounce((input: string, callback) => {
    const generateURL = () => {
      const params = new URLSearchParams({ query: input, ...additionalParams });
      return `${url}?${params.toString()}`;
    };

    axios.get(generateURL()).then(response => callback(response.data.filter((u: SearchUser) => {
      const ids = users.map(user => user.id);
      return !ids.includes(u.id);
    })));
  }, 500), [additionalParams, url, users]);

  const handleChange = (user: SearchUser) => {
    setValue(user);
    onChange({ target: { name, value: user } });
  };

  useEffect(() => {
    if (resetSearch) {
      setValue(null);
    }

    if (resetSearch && setResetSearch) {
      setResetSearch(false);
    }
  }, [resetSearch, setResetSearch, setValue]);

  return (
    <React.Fragment>
      <label htmlFor="userSearch" className="sr-only">
        User Search
      </label>

      <AsyncSelect
        id="userSearch"
        className="user-search"
        name={name}
        onChange={handleChange}
        backspaceRemovesValue
        loadOptions={debouncedSearch}
        value={value}
        formatOptionLabel={(option: SearchUser) => formatLabel(option)}
        controlShouldRenderValue={displaySelectedValues}
        getOptionValue={(option: SearchUser) => option.id}
        placeholder={placeholder}
        isClearable={isClearable}
        theme={theme => overrideThemes(theme)}
        styles={{
          menu: (base: object) => ({ ...base, zIndex: 3 }),
          control: (base: object) => overrideControl(base),
        }}
        components={{
          ...overrideComponents(),
          NoOptionsMessage,
        }}
      />
    </React.Fragment>
  );
};

export default UserSearch;
