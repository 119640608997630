import React, { useState, useEffect } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { CaratLeft, CaratRight } from 'ui/components/Icons';
import 'keen-slider/keen-slider.min.css';

interface Props {
  children: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  perView: number;
  spacing: number;
}

export const Carousel: React.FC<Props> = ({ children, perView, spacing }) => {
  const [visibleSlides, setVisibleSlides] = useState<number>(perView);

  const handleChange = (e) => {
    setVisibleSlides(e.options?.slides?.perView);
  };

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    mode: 'snap',
    optionsChanged: handleChange,
    slides: { perView, spacing },
    breakpoints: {
      '(max-width: 1440px)': {
        slides: { perView, spacing: 10 },
      },
      '(max-width: 1024px)': {
        slides: { perView: Math.min(Math.max(perView - 1, 1), 3), spacing: 10 },
      },
      '(max-width: 768px)': {
        slides: { perView: Math.min(Math.max(perView - 2, 1), 2), spacing: 10 },
      },
      '(max-width: 480px)': {
        slides: { perView: Math.max(perView - 3, 1), spacing: 10 },
      },
    },
  });

  useEffect(() => {
    instanceRef?.current?.update();
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="carousel">
      {children.length > 0 && (
        <React.Fragment>
          <div ref={sliderRef} className="keen-slider flex overflow-hidden mb-6">
            {children && children.map((child, index) => (
              <div key={index} className="keen-slider__slide"> {/* eslint-disable-line react/no-array-index-key */}
                {child}
              </div>
            ))}
          </div>

          {children.length > visibleSlides && (
            <div className="flex items-center justify-center gap-5 mb-2">
              <div
                role="button"
                tabIndex={0}
                aria-label="Previous Item"
                onClick={() => instanceRef.current?.prev()}
                onKeyDown={e => e.key === 'Enter' && instanceRef.current?.prev()}
              >
                <CaratLeft width="w-[12px]" height="h-[20px]" />
              </div>

              <div
                role="button"
                tabIndex={0}
                aria-label="Next Item"
                onClick={() => instanceRef.current?.next()}
                onKeyDown={e => e.key === 'Enter' && instanceRef.current?.next()}
              >
                <CaratRight width="w-[12px]" height="h-[20px]" />
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </section>
  );
};

export default Carousel;
