import React from 'react';

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const Heading: React.FC<Props> = ({ title, children }) => (
  <h1 className="text-h2">
    <div>
      {title}
    </div>
    <div>
      {children}
    </div>
  </h1>
);

export default Heading;
